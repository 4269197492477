import React from "react";

import PersonalData from "../../components/PersonalData";

function Home() {
  return (
      <PersonalData />
  );
}

export default Home;
