import React from 'react';
import DisplayChart from './displayChart';
import MedicinesApp from './medicinesForm'; 
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

function Medicines()  {
	return (
		<div style={{}}>
			<Card
				style={{
				width: 800,
				backgroundColor: "#ffffeh",
				}}
			>
				<CardContent>
					<h1> Medicines </h1>
					<div className="container">
						<div className="row align-items-center my-5">
							<MedicinesApp/>
							<br />
						</div>
						<div className="table_container">
							<br />
							<DisplayChart />
						</div>
					</div>
				</CardContent>
				{/* <CardActions>
				<Button size="small">Stay Safe.....</Button>
				</CardActions> */}
			</Card>
		</div>
	)
}


export default Medicines  ; //new