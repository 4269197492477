import React, { useState } from "react";
import {ExcerciseApp, MedicinesApp, MotionsApp, SleepApp, StatsApp, CheatApp } from '../../pages';

export default function BaseForm() {
  const [selected, setSelected] = useState("");

  const selectionChanged = (e) => setSelected(e.target.value);

  return (
    <div className="App">
      {/* SELECT COMPONENT */}
      <select value={selected} onChange={selectionChanged}>
        <option value=""></option>
        <option value="Cheat">Cheat</option>
        <option value="Excercise">Excercise</option>
        <option value="Medicines">Medicines</option>
        <option value="Motions">Motions</option>
        <option value="Sleep">Sleep</option>
        <option value="Stats">Stats</option>
      </select>

      {/* IF OPTION 1 IS SELECTED */}
      {selected === "Cheat" && (
        <>
          <CheatApp />
        </>
      )}

      {/* IF OPTION 1 IS SELECTED */}
      {selected === "Excercise" && (
        <>
          <ExcerciseApp />
        </>
      )}

      {/* IF OPTION 1 IS SELECTED */}
      {selected === "Medicines" && (
        <>
          <MedicinesApp />
        </>
      )}

      {/* IF OPTION 1 IS SELECTED */}
      {selected === "Motions" && (
        <>
          <MotionsApp />
        </>
      )}

      {/* IF OPTION 1 IS SELECTED */}
      {selected === "Sleep" && (
        <>
          <SleepApp />
        </>
      )}

      {/* IF OPTION 1 IS SELECTED */}
      {selected === "Stats" && (
        <>
          <StatsApp />
        </>
      )}

      {/* IF OPTION 2 IS SELECTED */}
      {selected === "" && (
        <>
          <h1>Please select an option above</h1>
        </>
      )}
    </div>
  );
}