import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import {FormApp} from '../../pages';

// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { personalData } from "../../config/dataApi";
import useStyles from "./styles";
// import ButtonLink from "../ButtonLink";

function PersonalData() {
  const classes = useStyles();

  const {
    greeting,
    name,
    description,
    email,
    profilePicture,
    socialMedias,
  } = personalData;

  return (
    <div className={classes.root}>
      <Avatar
        alt="Profile picture"
        src={profilePicture}
        className={classes.avatar}
      />
      <Grid container className={classes.text}>
        <Grid item s={12}>
          <Typography variant="h2">{`${greeting} ${name}`}</Typography>
        </Grid>
        <Grid item s={12}>
          <Typography variant="h5">{description}</Typography>
        </Grid>
        <Grid item s={12}>
          <Typography variant="button">{email}</Typography>
        </Grid>
        <Grid item s={12}>
          {/* <IconButton aria-label="email" component="a" href={`mailto:${email}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </IconButton> */}
          {socialMedias.map((socialMedia) => (
            <IconButton
              key={socialMedia.name}
              aria-label={socialMedia.name}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={socialMedia.url}
            >
              <FontAwesomeIcon icon={["fab", socialMedia.name]} />
            </IconButton>
          ))}
        </Grid>
        {/* <Grid item xs={12}>
          <ButtonLink to={"/form"} primary={"See my portfolio"} />
        </Grid> */}
          <Card
          style={{
          width: 1200,
          backgroundColor: "#ffffeh",
          }}
        >
            <CardContent>
              <div className="container">
                <div className="row align-items-center my-5">
                  <FormApp/>
                  <br />
                </div>
                {/* <div className="table_container">
                  <br />
                  <DisplayChart />
                </div> */}
              </div>
            </CardContent>
            {/* <CardActions>
            <Button size="small">Stay Safe.....</Button>
            </CardActions> */}
        </Card>
      </Grid>
    </div>
  );
}

export default PersonalData;