import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import {Excercise, Medicines, Motions, Sleep, Stats, Cheat , FormApp} from './pages';

function Routes() {
  return (
    <Switch>
      <Route path="/excercise">
        <Excercise />
      </Route>
      <Route path="/medicines">
        <Medicines />
      </Route>
      <Route path="/motions">
        <Motions />
      </Route>
      <Route path="/sleep">
        <Sleep />
      </Route>
      <Route path="/stats">
        <Stats />
      </Route>
      <Route path="/cheat">
        <Cheat />
      </Route>
      <Route path="/form">
        <FormApp />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default Routes;
