import React, { Component } from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Medicine',
      timeOfDay: '',
      quantity: '',
      medicine: '',
      notes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
  
    await axios.post(
      'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker', null,
      {params: this.state}
    );
  }

  render() {

    return (
      <div >
        <div >
          <form onSubmit={this.handleSubmit}>
          <div >
          <br />Time of Day{'\u00A0'}{'\u00A0'}{'\u00A0'}
              <input 
              type="radio" 
              value="Morning" 
              name="timeOfDay" 
              onChange={this.handleChange}/> Morning {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="Evening" 
              name="timeOfDay" 
              onChange={this.handleChange}/> Evening {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="Night" 
              name="timeOfDay" 
              onChange={this.handleChange}/> Night<br />
          </div>
          <div >
            <br />Quantity{'\u00A0'}{'\u00A0'}{'\u00A0'}
              <input 
              type="radio" 
              value="1" 
              name="quantity" 
              onChange={this.handleChange}/> 1 {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="2" 
              name="quantity" 
              onChange={this.handleChange}/> 2 {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="3" 
              name="quantity" 
              onChange={this.handleChange}/> 3 {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="4" 
              name="quantity" 
              onChange={this.handleChange}/> 4 {'\u00A0'} {'\u00A0'}
          </div>
          <div>
          <br /><label>Medicine:</label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
          <select name="medicine" value={this.state.quantity} onChange={this.handleChange}>
          <option></option>
            <option value="Pentasa">Pentasa</option>
            <option value="Pasupu">Pasupu</option>
            <option value="Iron">Iron</option>
            <option value="Homeo">Homeo</option>
            <option value="Homeo Powder">Homeo Powder</option>
            <option value="B12"> B12</option>
            <option value="MultiVitamin">Multi Vitamin</option>
            <option value="Ashwaganda">Ashwaganda</option>
            <option value="Vitamin C">Vitamin C</option>
            <option value="Ashwaganda">Ashwaganda</option>
            <option value="Akkermansia">Akkermansia</option>
          </select>
          </div>
            <div>
            <br /><label>Notes : </label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
            <TextareaAutosize name="notes"
                rows={5}
                cols={5}
                onChange={this.handleChange}/>
            </div>
          <br /><button className="buttonW" type="submit" size="lg">Send</button>
          </form>
        </div>
      </div>
    );
  }
}