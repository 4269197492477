import React, { Component } from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Excercise',
      timeofDay: '',
      activity: '',
      completed: '',
      timeSpent: '',
      notes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state)
    await axios.post(
      'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker', null,
      {params: this.state}
    );
  }

  render() {
    return (
      <div >
        <div >
            <form onSubmit={this.handleSubmit}>
            <div onChange={this.onChangeValue}>
            <br />Time of the Day
              <input type="radio" value="Morning" name="timeofDay" onChange={this.handleChange}/> Morning {'\u00A0'} {'\u00A0'}
              <input type="radio" value="Afternoon" name="timeofDay" onChange={this.handleChange}/> Afternoon {'\u00A0'} {'\u00A0'}
              <input type="radio" value="Evening" name="timeofDay" onChange={this.handleChange}/> Evening {'\u00A0'} {'\u00A0'}
            </div>
            <div>
              <br />
            <label>Time Spent in Minutes:</label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
            <select name="timeSpent" value={this.state.timeSpent} onChange={this.handleChange}>
            <option defaultValue="10"></option>
              <option value="0">None</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
              <option value="basketBall">Basket Ball</option>
            </select>
            </div>
            <div>
              <br />
            <label>Activity:</label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
            <select name="activity" value={this.state.activity} onChange={this.handleChange}>
            <option defaultValue="Bike"></option>
              <option value="NeighborHood">Neighbor Hood</option>
              <option value="Family Outdoor">Family Activity</option>
              <option value="Biking">Biking</option>
              <option value="Tennis">Tennis</option>
              <option value="Soccer">Soccer</option>
              <option value="BasketBall">Basket Ball</option>
            </select>
            </div>
            <div onChange={this.onChangeValue}>
            <br />Completed
              <input type="radio" value="Yes" name="completed" onChange={this.handleChange}/> Yes {'\u00A0'} {'\u00A0'}
              <input type="radio" value="No" name="completed" onChange={this.handleChange}/> No<br /><br />
            </div>
            <div>
              <label>Notes : </label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
              <TextareaAutosize name="notes"
                rows={5}
                cols={5}
                onChange={this.handleChange}/>
                <br />
            </div>
            <br /><button type="submit">Send</button>
          </form>
        </div>
      </div>
    );
  }
}
