import React, { Component } from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Stats',
      stat: '',
      timeOfDay: '',
      value: '',
      notes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    await axios.post(
      'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker', null,
      {params: this.state}
    );
  }

  render() {

    return (
      <div >
        {/* <FormApp /> */}
        <div >
            <form onSubmit={this.handleSubmit}>           
            <div>
              <br />
            <label>Stat:</label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
            <select name="stat" value={this.state.timeSpent} onChange={this.handleChange}>
            <option defaultValue="Weight"></option>
              <option value="Weight">Weight</option>
              <option value="Height">Height</option>
            </select>
            </div>
            <div onChange={this.onChangeValue}>
            <br />Time Of Day
              <input type="radio" value="AM" name="timeOfDay" onChange={this.handleChange}/> AM {'\u00A0'} {'\u00A0'}
              <input type="radio" value="PM" name="timeOfDay" onChange={this.handleChange}/> PM<br /><br />
            </div>
            <div>
              <label>Value (Decimal) : </label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
              <input type="text" 
                name="value"
                onChange={this.handleChange}
              /> <br />
            </div>
            <div> <br />
              <label>Notes : </label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
              <TextareaAutosize name="notes"
                rows={5}
                cols={5}
                onChange={this.handleChange}/>
                <br />
            </div>
            <br /> <button type="submit">Send</button>
          </form>
        </div>
      </div>
    );
  }
}