import React, { useState, useEffect } from 'react';
import '../index.css';
 
function TableData() {

    const [data, getData] = useState([])
    const getURL = 'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker?activity=Motions';

    useEffect(() => {
        fetchData()
    }, [])
 
 
    const fetchData = () => {
        fetch(getURL)
            .then((res) =>
                res.json())
            .then((response) => {
                console.log(response);
                getData(response);
            },
            // Note: it's important to handle errors here 
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components
            err => {
                console.log("Error")
            });
    }
 
    return (
        <>
         <h1>Basis Stats captures in past one Day</h1>
          <table>
            <tbody>
                <tr>
                    <th>Activity</th>
                    <th>Bloody</th>
                    <th>Formed</th>
                    <th>Time Of Day</th>
                    <th>Date</th>
                </tr>
                {data.map((item, i) => (
                    <tr key={i}>
                        <td><b>{item.activity}</b></td>
                        <td>{item.bloody}</td>
                        <td>{item.formed}</td>
                        <td>{item.timeSpent}</td>
                        <td>{item.time_stamp}</td>
                    </tr>
                ))}
            </tbody>
          </table>
          {/* <div style={{}}>
                <h4>How to use CardComponent in ReactJS?</h4>
                <Card
                    style={{
                    width: 600,
                    backgroundColor: "white",
                    }}
                >
                    <CardContent>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Activity</th>
                                    <th>Bloody</th>
                                    <th>Formed</th>
                                    <th>Time Of Day</th>
                                    <th>Date</th>
                                </tr>
                                {data.map((item, i) => (
                                    <tr key={i}>
                                        <td><b>{item.activity}</b></td>
                                        <td>{item.bloody}</td>
                                        <td>{item.formed}</td>
                                        <td>{item.timeSpent}</td>
                                        <td>{item.time_stamp}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </CardContent>
                    <CardActions>
                    <Button size="small">Stay Safe.....</Button>
                    </CardActions>
                </Card>
                </div> */}
        </>
    );
}
 
export default TableData;