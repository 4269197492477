import React from 'react';
import MotionsApp from './motionsForm'; 
import DisplayChart from './displayChart'; 
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

function Motionspage () {
	return (
		<div style={{}}>
			<Card
				style={{
				width: 800,
				backgroundColor: "#ffffeh",
				}}
			>
				<CardContent>
					<h1> Motions </h1>
					<div className="container">
						<div className="row align-items-center my-5">
							<MotionsApp/>
							<br />
						</div>
						<div className="table_container">
							<br />
							<DisplayChart />
						</div>
					</div>
				</CardContent>
				{/* <CardActions>
				<Button size="small">Stay Safe.....</Button>
				</CardActions> */}
			</Card>
			</div>
	)
}


export default Motionspage ; //new
