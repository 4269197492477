import React, { Component } from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';

export default class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: 'Motions',
      timeofDay: '',
      formed: '',
      bloody: '',
      timePicker: '',
      notes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.state['action']='saveTracker';
    await axios.post(
      'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker', null,
      {params: this.state}
    );
  }

  render() {
    return (
      <div >
        {/* <FormApp /> */}
        <div >
            <form onSubmit={this.handleSubmit}>
            <div onChange={this.onChangeValue}>
            <br />Time of the Day<br />
              <input type="radio" value="morning" name="timeofDay" onChange={this.handleChange}/> Morning {'\u00A0'}
              <input type="radio" value="afternoon" name="timeofDay" onChange={this.handleChange}/> Afternoon {'\u00A0'}
              <input type="radio" value="evening" name="timeofDay" onChange={this.handleChange}/> Evening {'\u00A0'}
              <input type="radio" value="night" name="timeofDay" onChange={this.handleChange}/> Night {'\u00A0'}
              <input type="radio" value="middleOfNight" name="timeofDay" onChange={this.handleChange}/> Middle of Night {'\u00A0'}
              <input type="radio" value="accident" name="timeofDay" onChange={this.handleChange}/> Oopsie<br /><br />
            </div>
            <div onChange={this.onChangeValue}>
            <br />Bloody<br />
              <input type="radio" value="Lot" name="bloody" onChange={this.handleChange}/> Lot {'\u00A0'}
              <input type="radio" value="Little" name="bloody" onChange={this.handleChange}/> Little {'\u00A0'}
              <input type="radio" value="Wipe" name="bloody" onChange={this.handleChange}/> Wipe {'\u00A0'}
              <input type="radio" value="No" name="bloody" onChange={this.handleChange}/> No<br /><br />
            </div>
            <div onChange={this.onChangeValue}>
            <br /> Formed
              <input type="radio" value="Yes" name="formed" onChange={this.handleChange}/> Yes {'\u00A0'}
              <input type="radio" value="No" name="formed" onChange={this.handleChange}/> No<br /><br />
            </div>
            <div>
              <label>Notes : </label>{'\u00A0'}{'\u00A0'}
              <TextareaAutosize name="notes"
                rows={5}
                cols={5}
                onChange={this.handleChange}/>
                <br />
            </div>
            <br /><button type="submit">Send</button>
          </form>
        </div>
      </div>
    );
  }
}