import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';


export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Sleep',
      quality: '',
      quantity: '',
      improved: '',
      timeofDay: '',
      notes: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    await axios.post(
      'https://pxr8nz5d10.execute-api.us-east-1.amazonaws.com/v1/tracker', null,
      {params: this.state}
    );
  }

  render() {

    return (
      <div >
        {/* <FormApp /> */}
        <div >
          <form onSubmit={this.handleSubmit}>
            
          <div >
          <div onChange={this.onChangeValue}>
            <br />Time of the Day
              <input type="radio" value="Morning" name="timeofDay" onChange={this.handleChange}/> Morning {'\u00A0'} {'\u00A0'}
              <input type="radio" value="Afternoon" name="timeofDay" onChange={this.handleChange}/> Afternoon {'\u00A0'} {'\u00A0'}
              <input type="radio" value="Evening" name="timeofDay" onChange={this.handleChange}/> Evening {'\u00A0'} {'\u00A0'}
            </div>
          <br />Quality
              <input 
              type="radio" 
              value="Yes" 
              name="quality" 
              onChange={this.handleChange}/> Yes {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="No" 
              name="quality" 
              onChange={this.handleChange}/> No<br />
          </div>
          <div >
          <br />Improved
              <input 
              type="radio" 
              value="Yes" 
              name="improved" 
              onChange={this.handleChange}/> Yes {'\u00A0'} {'\u00A0'}
              <input 
              type="radio" 
              value="No" 
              name="improved" 
              onChange={this.handleChange}/> No<br />
          </div>
            <div>
            <br /><label>Time in Hours:</label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
            <select name="quantity" value={this.state.quantity} onChange={this.handleChange}>
            <option defaultValue="10"></option>
              <option value="0">None</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
            </div>
            <div>
            <br /><label>Notes : </label>{'\u00A0'} {'\u00A0'}{'\u00A0'} {'\u00A0'}
              <TextareaAutosize name="notes"
                rows={5}
                cols={5}
                onChange={this.handleChange}/>
               <br />
            </div>
            <br /><div><button type="submit">Send</button></div>
          </form>
        </div>
      </div>
    );
  }
}